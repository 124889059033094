<template>
  <div class="px-4 py-5 space-y-6 sm:p-12">
    <img class="text-center w-1/3 m-auto" src="https://media.publit.io/file/2026980_check_email_mail_receive_send_icon.png"  />
    <h2 class="mt-8 text-xl text-center font-extrabold text-gray-900">
      Please Confirm Your Email
    </h2>
    <p class="mt-2 text-sm text-gray-600 text-center">
    Please check your email inbox. We have sent you an email containing a link to validate your email address.
    </p>
  </div>
</template>
<script>
import Firebase from '../fbHelpers.js';

export default {
  mounted() {
    const app = this;
    if(this.$route.query.userToken) {
      Firebase.onEvent("confirmedEmail", this.$route.query.userToken,(message) => {
        if(message.redirect) {
          window.location.href = message.redirect + "?userToken=" + app.$route.query.userToken;
        }
      });
    }
  },
  data() {
    return {
      confirmed: false
    }
  }
}
</script>